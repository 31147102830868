import React, { Fragment } from "react"
import NwpCalculatorSelect from "./NwpCalculatorSelect"
import NwpAreaSelectRow from "./NwpAreaSelectRow"
import NwpAccordion from "./NwpAccordion"
import { CSSTransitionGroup } from "react-transition-group"
import { translate } from "../../../../Services/translation"

// import drainImage from '../../../../../images/Calculator/d.png';

const NwpCalculator = ({
    company,
    data,
    options,
    setVariable,
    requestPdf,
    increaseRoofAreaCount,
    decreaseRoofAreaCount,
    mutateRoofArea,
    selectedRiskFactor,
    selectedSurfaceCoefficient,
    selectedMunicipality,
    selectedTime,
    selectedYear,
    selectedIssuedTo,
    selectedObjectName,
    selectedRoofer,
    selectedMadeBy,
}) => {
    return (
        <>
            <div className={company == "Trebolit" ? "Trebolit" : "Mataki"}>
                <div className="calculator-block">
                    <div className="container h-p-zero">
                        <div className="row">
                            <div className="columns small-8 align-self-top">
                                <h1 className="h2">{data.title}</h1>
                                <div dangerouslySetInnerHTML={{ __html: data.text }} />
                            </div>
                            <div className="columns small-4">
                                <img className="fluid-image" src={data.calculatorHeaderImageLink} alt="" />
                            </div>
                        </div>
                        {options && (
                            <Fragment>
                                <form onSubmit={(event) => requestPdf(event, company)}>
                                    <div className="row">
                                        <div className="columns small-12">
                                            <h2 className="padding">{translate("calculator.basedata.heading")}</h2>
                                        </div>

                                        <div className="columns small-6">
                                            <label htmlFor="municipality">{translate("calculator.basedata.geographiclocation.label")}</label>
                                            <NwpCalculatorSelect
                                                id="municipality"
                                                placeholder={translate("calculator.basedata.geographiclocation.placeholder")}
                                                value={selectedMunicipality}
                                                onChange={(value) => setVariable("selectedMunicipality", value)}
                                                options={options.municipalityOptions}
                                            />
                                        </div>

                                        <div className="columns small-6">
                                            <label htmlFor="surfaceCoefficient">{translate("calculator.basedata.surface.label")}</label>
                                            <NwpCalculatorSelect
                                                id="surfaceCoefficient"
                                                placeholder={translate("calculator.basedata.surface.placeholder")}
                                                value={selectedSurfaceCoefficient}
                                                onChange={(value) => setVariable("selectedSurfaceCoefficient", value)}
                                                options={options.surfaceCoefficientOptions}
                                                defaultValue={0}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="columns small-12">
                                            <NwpAccordion label={translate("calculator.settings.heading")} visible={false}>
                                                <div className="columns small-6">
                                                    <label htmlFor="intensityTime">{translate("calculator.settings.intensitytime.label")}</label>
                                                    <NwpCalculatorSelect
                                                        id="intensityTime"
                                                        placeholder={translate("calculator.settings.intensitytime.placeholder")}
                                                        value={selectedTime}
                                                        onChange={(value) => setVariable("selectedTime", value)}
                                                        options={options.timeOptions}
                                                        defaultValue={1}
                                                    />
                                                </div>

                                                <div className="columns small-6">
                                                    <label htmlFor="intensityYear">{translate("calculator.settings.intensityyear.label")}</label>
                                                    <NwpCalculatorSelect
                                                        id="intensityYear"
                                                        placeholder={translate("calculator.settings.intensityyear.placeholder")}
                                                        value={selectedYear}
                                                        onChange={(value) => setVariable("selectedYear", value)}
                                                        options={options.yearOptions}
                                                        defaultValue={1}
                                                    />
                                                </div>

                                                <div className="columns small-6">
                                                    <label htmlFor="riskFactor">{translate("calculator.settings.riskfactor.label")}</label>
                                                    <NwpCalculatorSelect
                                                        id="riskFactor"
                                                        placeholder={translate("calculator.settings.riskfactor.placeholder")}
                                                        value={selectedRiskFactor}
                                                        onChange={(value) => setVariable("selectedRiskFactor", value)}
                                                        options={options.riskFactorOptions}
                                                        defaultValue={0}
                                                    />
                                                </div>
                                            </NwpAccordion>
                                        </div>
                                    </div>

                                    <CSSTransitionGroup transitionName="fade-in" transitionEnterTimeout={500} transitionLeaveTimeout={300}>
                                        {data.selectedMunicipality && (
                                            <Fragment>
                                                {data.selectedRoofAreas.map((i, index) => {
                                                    return (
                                                        <NwpAreaSelectRow
                                                            visible={true}
                                                            label={`${translate("calculator.ceilingarea.heading")} #${index + 1}`}
                                                            key={index}
                                                            index={index}
                                                            onChange={(value) => mutateRoofArea(index, value)}
                                                            flowCapacityOptions={options.flowCapacityOptions}
                                                            productLink={data.calculatorProductLink}
                                                        ></NwpAreaSelectRow>
                                                    )
                                                })}

                                                <div className="row">
                                                    <div className="columns small-12">
                                                        <input
                                                            className="btn calculator__submit h-mr-sm"
                                                            type="button"
                                                            value={translate("calculator.ceilingarea.add.button")}
                                                            onClick={() => increaseRoofAreaCount()}
                                                        ></input>
                                                        {data.selectedRoofAreas.length > 1 && (
                                                            <input
                                                                className="btn btn--outlined calculator__submit"
                                                                type="button"
                                                                value={translate("calculator.ceilingarea.remove.button")}
                                                                onClick={() => decreaseRoofAreaCount()}
                                                            ></input>
                                                        )}
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )}
                                    </CSSTransitionGroup>

                                    <div className="row">
                                        <div className="columns small-12">
                                            <h2 className="padding">{translate("calculator.printoutofdimensioning.header")}</h2>
                                        </div>

                                        <div className="columns small-6">
                                            <label htmlFor="issuedTo">{translate("calculator.printoutofdimensioning.issuedto.label")}</label>
                                            <input
                                                id="issuedTo"
                                                required
                                                className="calculator__text"
                                                placeholder=""
                                                value={selectedIssuedTo}
                                                onChange={(value) => setVariable("selectedIssuedTo", value.target.value)}
                                            />
                                        </div>

                                        <div className="columns small-6">
                                            <label htmlFor="objectName">{translate("calculator.printoutofdimensioning.objectname.label")}</label>
                                            <input
                                                id="objectName"
                                                required
                                                className="calculator__text"
                                                placeholder=""
                                                value={selectedObjectName}
                                                onChange={(value) => setVariable("selectedObjectName", value.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="columns small-6">
                                            <label htmlFor="roofer">{translate("calculator.printoutofdimensioning.roofer.label")}</label>
                                            <input
                                                id="roofer"
                                                required
                                                className="calculator__text"
                                                placeholder=""
                                                value={selectedRoofer}
                                                onChange={(value) => setVariable("selectedRoofer", value.target.value)}
                                            />
                                        </div>

                                        <div className="columns small-6">
                                            <label htmlFor="madeBy">{translate("calculator.printoutofdimensioning.madeby.label")}</label>
                                            <input
                                                id="madeBy"
                                                required
                                                className="calculator__text"
                                                placeholder=""
                                                value={selectedMadeBy}
                                                onChange={(value) => setVariable("selectedMadeBy", value.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="columns small-12">
                                            <input
                                                className="btn calculator__submit download"
                                                type="submit"
                                                value={translate("calculator.printoutofdimensioning.download.button")}
                                            ></input>
                                        </div>
                                    </div>
                                </form>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default NwpCalculator
