import { CSSTransitionGroup } from "react-transition-group"
import React, { Component, Fragment } from "react"
import NwpCalculatorSelect from "./NwpCalculatorSelect"
import NwpDrainageDisplay from "./NwpDrainageDisplay"
import NwpAccordion from "./NwpAccordion"
import { translate } from "../../../../Services/translation"

class NwpAreaSelectRow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            drainageDiameterOptions: [...new Set(props.flowCapacityOptions.map((x) => x.value.diameter))].map((item, index) => {
                return { label: `${item} mm`, value: item }
            }),
            drainageTypeOptions: [...new Set(props.flowCapacityOptions.map((x) => x.value.type))].map((item, index) => {
                return item
            }),
            show: this.props.show !== undefined ? this.props.show : false,
            value: {
                name: "",
                roofArea: 0,
                extraRoofArea: 0,
                selectedDrainageType: 0,
                drainDiameter: {},
                complete: false,
            },
            resultValues: [],
        }
    }

    onValueChanged(prop, value) {
        var newValue = this.state.value
        newValue[prop] = value

        this.setState({ value: newValue })

        if (this.props.onChange) {
            if (newValue.resultValues) {
                this.setState({ resultValues: newValue.resultValues })
            }
            this.props.onChange(newValue)
        }
    }

    componentDidUpdate() {
        let completeStatus = this.isComplete()

        if (completeStatus !== this.state.value.complete) {
            this.onValueChanged("complete", completeStatus)
        }
    }

    isComplete() {
        return this.state.value.roofArea > 0 && !this.isEmpty(this.state.value.drainDiameter)
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false
        }
        return true
    }

    toggleShow(value) {
        this.setState({ show: value })
    }

    getFlowCapacityOption(diameter, type) {
        var element = this.props.flowCapacityOptions.filter((x) => x.value.diameter === Number(diameter.value) && x.value.type === Number(type))
        return element[0]
    }

    render() {
        const { complete, drainDiameter, selectedDrainageType } = this.state.value
        const drainageDisplay = complete && (
            <div className="columns small-6">
                <div className="row align-center text-center">
                    <div className="columns small-12 pv3">
                        <label>{translate("calculator.ceilingarea.drainagetype.label")}</label>
                    </div>
                    <NwpDrainageDisplay
                        mappedOption={this.getFlowCapacityOption(drainDiameter, selectedDrainageType)}
                        index={this.props.index}
                        resultValues={this.state.value.resultValues}
                        options={this.state.drainageTypeOptions}
                        onChange={(value) => this.onValueChanged("selectedDrainageType", value)}
                        productLink={this.props.productLink}
                    ></NwpDrainageDisplay>
                </div>
            </div>
        )

        return (
            <Fragment>
                <NwpAccordion
                    label={this.state.value.name ? this.state.value.name : this.props.label}
                    resultValues={this.state.value.resultValues}
                    visible={this.props.visible}
                >
                    <div className="columns small-6">
                        <div className="row">
                            <div className="columns small-12">
                                <label>{translate("calculator.ceilingarea.nameornote.label")}</label>
                                <input
                                    className="calculator__text"
                                    placeholder=""
                                    value={this.state.value.name}
                                    onChange={(value) => this.onValueChanged("name", value.currentTarget.value)}
                                />
                            </div>

                            <div className="columns small-12">
                                <label>{translate("calculator.ceilingarea.area.label")}</label>
                                <input
                                    className="calculator__numeric"
                                    type="number"
                                    min="0"
                                    placeholder={translate("calculator.ceilingarea.area.placeholder")}
                                    value={this.state.value.roofArea}
                                    onChange={(value) => this.onValueChanged("roofArea", value.currentTarget.value)}
                                />
                            </div>

                            <div className="columns small-12">
                                <label>{translate("calculator.ceilingarea.connectingsurfacesarea.label")}</label>
                                <input
                                    className="calculator__numeric"
                                    type="number"
                                    min="0"
                                    placeholder={translate("calculator.ceilingarea.connectingsurfacesarea.placeholder")}
                                    value={this.state.value.extraRoofArea}
                                    onChange={(value) => this.onValueChanged("extraRoofArea", value.currentTarget.value)}
                                />
                            </div>

                            <div className="columns small-12">
                                <label>{translate("calculator.ceilingarea.roofdraindiameter.label")}</label>

                                <NwpCalculatorSelect
                                    placeholder={translate("calculator.ceilingarea.roofdraindiameter.placeholder")}
                                    value={this.state.value.drainDiameter}
                                    onChange={(value) => this.onValueChanged("drainDiameter", value)}
                                    options={this.state.drainageDiameterOptions}
                                />
                            </div>
                        </div>
                    </div>

                    <CSSTransitionGroup component={React.Fragment} transitionName="fade-in" transitionEnterTimeout={500} transitionLeaveTimeout={300}>
                        {drainageDisplay}
                    </CSSTransitionGroup>
                </NwpAccordion>
            </Fragment>
        )
    }
}

export default NwpAreaSelectRow
